<template lang="pug">
.options-setting.flex.column.p-20.gap-20.overflow-overlay
  .table-container.fill
    table
      thead
        tr
          th.hour 時間長短(分鐘)
          th.op
      tbody
        tr(v-for="(opt, idx) in options", :key="idx")
          td.hour
            input(type="number", min="1", v-model="opt.value")
          td.op
            md-icon.clickable(@click.native="removeOpt(idx)") delete
        tr
          td(colspan="3")
            dd-button(
              @click.native="addOpt") 新增
  dd-button(
    @click.native="save"
    type="primary") 儲存
</template>

<script>
import { mapMutations } from 'vuex';
import { getBufferTimes, updateBufferTimes } from '@/api/reserve';

export default {
  name: 'fish-buffer-setting',
  data() {
    return {
      options: [],
    };
  },
  methods: {
    ...mapMutations(['setHeaderTitle']),
    removeOpt(idx) {
      this.options.splice(idx, 1);
      this.$forceUpdate();
    },
    addOpt() {
      const maxValue = this.options.reduce((ret, opt) => {
        const value = parseInt(opt.value, 10);
        if (value > ret) {
          return value;
        }
        return ret;
      }, 0);
      this.options.push({
        value: maxValue + 5,
      });
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadOptions();
      });
    },
    async loadOptions() {
      const rsp = await getBufferTimes();
      const opts = (rsp.data || []).map((opt) => ({
        value: parseInt(opt, 10),
      }));
      opts.sort((opt1, opt2) => opt1.value - opt2.value);
      this.options = opts;
    },
    save() {
      this.$execWithLoading(async () => {
        const data = this.options.map((opt) => opt.value);
        await updateBufferTimes(data);
        await this.loadOptions();
        this.$showSuccess('更新成功');
      }, (err) => {
        this.$showError('更新失敗');
        console.log(err);
      });
    },
  },
  mounted() {
    this.setHeaderTitle('釣魚緩衝時間設定');
    this.loadData();
  },
};
</script>

  <style lang="scss" scoped>
  .options-setting {
    table {
      input {
        width: 100%;
        height: 100%;
        font-size: 20px;
        border: 1px solid #ccc;
        text-align: center;
        padding: 8px;
      }
      .hour {
        width: 40%;
        padding: 0;
      }
      .price {
        width: 40%;
      }
      .op {
        width: 10%;
        i {
          color: #F42C04;
        }
      }
    }
  }
  </style>
